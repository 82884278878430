import "./App.css";
import RegistrationForm from "./Registration/dangky";
import TargetDateForm from "./Registration/TargetDateForm";

function App() {
  return (
    <div className="App">
      <RegistrationForm />
      {/* <TargetDateForm /> */}
    </div>
  );
}

export default App;
