import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "daterangepicker/daterangepicker.css";
import moment from "moment";
import $ from "jquery";
import "daterangepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import img from "../z5784129794910_ba0d60b0a77006a8d378abd2f72684b9.jpg";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    numberPhone: "",
    date: "",
    gender: "",
    cccd: "",
    imageUrl1: null,
    imageUrl2: null,
    tdvh: "",
    email: "",
    chuyenmon: "",
    chuyennganh: "",
    taynghe: "",
    vtut: "",
    tttd: "",
  });

  const [targetDate, setTargetDate] = useState("");
  const [youFrom, setYouFrom] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isCheck, setIsCheck] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // v2
  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;
  //   setFormData({ ...formData, [name]: files[0] });
  // };

  // v3
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFormData({ ...formData, [name]: files[0] });
    }
  };

  // setIsOverlayVisible(true);

  useEffect(() => {
    // Initialize the datepicker
    $("input.single-date").daterangepicker(
      {
        singleDatePicker: true,
        showDropdowns: true,
        locale: {
          format: "DD/MM/YYYY",
          monthNames: [
            "Tháng 1",
            "Tháng 2",
            "Tháng 3",
            "Tháng 4",
            "Tháng 5",
            "Tháng 6",
            "Tháng 7",
            "Tháng 8",
            "Tháng 9",
            "Tháng 10",
            "Tháng 11",
            "Tháng 12",
          ],
          daysOfWeek: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
          applyLabel: "Áp dụng",
          cancelLabel: "Hủy",
          fromLabel: "Từ",
          toLabel: "Đến",
          customRangeLabel: "Tùy chọn",
          weekLabel: "Tuần",
        },
        minDate: moment().subtract(80, "years").toDate(),
        maxDate: moment().subtract(17, "years").toDate(),
        startDate: moment().subtract(18, "years").toDate(),
      },
      function (start) {
        setFormData((prevState) => ({
          ...prevState,
          date: start.format("DD/MM/YYYY"),
        }));
      }
    );
  }, []);

  useEffect(() => {
    const today = new Date().getDay();
    let nextTargetDay;

    // nextTargetDay = 4;
    nextTargetDay = 1;
    if (today === 0 || today === 1 || today === 2 || today === 3) {
      nextTargetDay = 4; // Nếu là thứ 2, thứ 3 hoặc thứ 4, chọn thứ 5 làm mục tiêu
    } else {
      nextTargetDay = 1; // Ngược lại, chọn thứ 2 làm mục tiêu
    }

    const date = new Date();
    date.setDate(date.getDate() + ((nextTargetDay - date.getDay() + 7) % 7));
    setTargetDate(format(date, "dd/MM/yyyy"));
  }, []);

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);

  useEffect(() => {
    // Kiểm tra từ trang nào chuyển qua
    const referrer = document.referrer;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get("f");

    if (from) {
      if (from === "ga") {
        setYouFrom("Banner cổng bảo vệ");
      } else if (from === "fb") {
        setYouFrom("Facebook");
      } else if (from === "yt") {
        setYouFrom("Youtube");
      } else if (from === "tt") {
        setYouFrom("TikTok");
      } else if (from === "re") {
        setYouFrom("Người thân giới thiệu");
      }
    } else {
      if (referrer) {
        setYouFrom(referrer);
      } else {
        setYouFrom("Website");
      }
    }

    // Kiểm tra thiết bị sử dụng
    const userAgentData = navigator.userAgentData || {};
    const isMobile = userAgentData.mobile || /Mobi/i.test(navigator.userAgent);
    setIsMobile(isMobile ? "Mobile" : "Desktop");
  }, []);

  const [xacnhan, setXacnhan] = useState(false);
  const [showXacnhan, setShowXacnhan] = useState(false);

  const handleCheck = (e) => {
    setXacnhan(e.target.checked);
  };

  const handleXacnhan = (e) => {
    if (xacnhan) {
      setShowXacnhan(true);
      // setIsOverlayVisible(true);
    } else {
      alert("Bạn đã đọc thông tin chưa, hay xác nhận thông tin để đăng ký !!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isCheck !== "875") {
      alert("3 số cuối hotline chưa đúng. Vui lòng kiểm tra lại");
      return;
    }
    setIsOverlayVisible(true);
    try {
      const formData = new FormData();
      formData.append("fullname", fullname);
      formData.append("email", email);
      formData.append("numberPhone", numberPhone);
      formData.append("date", date);
      formData.append("cccd", cccd);
      formData.append("gender", gender);
      formData.append("tdvh", tdvh);
      formData.append("taynghe", taynghe);
      formData.append("chuyenmon", chuyenmon);
      formData.append("chuyennganh", chuyennganh);
      formData.append("vtut", vtut);
      formData.append("tttd", tttd);
      formData.append("isForm", youFrom);
      formData.append("isMobile", isMobile);

      // Thay đổi ở đây
      if (imageUrl1) formData.append("images", imageUrl1);
      if (imageUrl2) formData.append("images", imageUrl2);

      console.log("imageUrl1:", imageUrl1);
      console.log("imageUrl2:", imageUrl2);

      const response = await axios.post(
        "https://servertd.congtien.com.vn/api/register",
        // "https://servertd.vietlonghung.com.vn/api/register",
        // "https://tuyendung-vlh.onrender.com/api/register",
        // "http://localhost:30002/api/register",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      setIsRequestSuccessful(true);
      // alert(
      //   // `Chúc mừng ${fullname} đã đăng ký thành công. \n Mời bạn đến Cổng bảo vệ Công ty vào lúc 7 giờ 30 phút sáng ngày ${targetDate} để được hướng dẫn đến địa điểm hoàn thiện thủ tục nhận việc (vui lòng mặc áo sơ mi trắng, mang theo CCCD bản gốc và hồ sơ ứng tuyển, chuẩn bị bút xanh, cơm trưa và nước uống để nhận việc)`
      //   `Nộp hồ sơ thành công`
      // );
      console.log("hello");
    } catch (error) {
      console.error(error);
      alert(
        `Đã có lỗi hãy kiểm tra lại.\nMọi thắc mắc vui lòng liên hệ Bộ phận nhân sự của Công ty để được hỗ trợ : \nThời gian hỗ trợ : 7:30 -16:30 \nHotline hỗ trợ: 0902 245 139`
      );
    } finally {
      // Ẩn overlay sau khi yêu cầu hoàn tất (thành công hoặc thất bại)
      setIsOverlayVisible(false);
    }
  };

  const {
    fullname,
    numberPhone,
    date,
    gender,
    cccd,
    imageUrl1,
    imageUrl2,
    email,
    tdvh,
    chuyenmon,
    chuyennganh,
    taynghe,
    vtut,
    tttd,
  } = formData;

  return (
    <div className="container bg-light mt-4">
      {isOverlayVisible && (
        <>
          <div className="overlay">
            <div className="spinner"></div>
            <h3>Vui lòng đợi</h3>
          </div>
        </>
      )}
      {isRequestSuccessful ? (
        <>
          <div className="registration-form-container">
            <h2>Đăng ký Thành Công</h2>
            <form className="registration-form">
              {vtut === "Nhân viên Văn phòng" ? (
                <div>
                  <label for="xacnhan" class="h4 lh-base ">
                    <b className="b-red"> Lưu ý.</b> <br />
                    Nếu Anh/chị ứng tuyển nhân viên văn phòng, vui lòng
                    <b className="b-red"> gửi CV </b>
                    qua email
                    <a href="mailto:nhansu@congtien.com.vn">
                      <b className="b-red"> nhansu@congtien.com.vn</b>
                    </a>
                    <br />
                    <b className="b-red">
                      Mọi thắc mắc vui lòng liên hệ Bộ phận nhân sự của Công ty
                      để được hỗ trợ : Thời gian hỗ trợ : 7:30 - 16:30 <br />
                      Hotline hỗ trợ: 0902 245 139
                    </b>
                  </label>
                </div>
              ) : (
                <div class="h4 lh-base ">
                  <label for="xacnhan" class=" lh-base ">
                    Chúc mừng bạn đã đăng ký ứng tuyển thành công trên Website
                    mời Anh/Chị đến Cổng bảo vệ Công ty CP May Công Tiến vào lúc
                    <b className="b-red"> 7 giờ 30 phút </b>sáng ngày
                    <b className="b-red"> {targetDate} </b>để được hướng dẫn
                    phỏng vấn và nhận việc.
                    <b className="b-red">
                      Vui lòng mặc áo sơ mi trắng, mang theo CCCD bản gốc và hồ
                      sơ ứng tuyển (nếu có);{" "}
                    </b>
                    chuẩn bị bút xanh, cơm trưa và nước uống để nhận việc trong
                    trường hợp ứng viên vượt qua vòng phỏng vấn ./. Chọn vào xác
                    nhận thông tin ứng tuyển này. <br />
                  </label>
                  <br />
                  <b className="b-red">
                    Mọi thắc mắc vui lòng liên hệ Bộ phận nhân sự của Công ty để
                    được hỗ trợ :<br /> Thời gian hỗ trợ : 7:30 - 16:30 <br />
                    Hotline hỗ trợ : 0902 245 139
                  </b>
                </div>
              )}
            </form>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-center pt-4 text-primary">
            PHIẾU ĐĂNG KÝ ỨNG TUYỂN
          </h1>
          <form
            enctype="multipart/form-data"
            className="registration-form"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <label htmlFor="fullname" className="form-label">
                Họ và Tên
              </label>
              <input
                type="text"
                className="form-control"
                id="fullname"
                name="fullname"
                required
                value={fullname}
                onChange={handleInputChange}
                placeholder="Nguyễn Văn A"
              />
              <div className="invalid-feedback">Nhập họ tên đầy đủ</div>
            </div>
            <div className="mb-4">
              <label htmlFor="numberPhone" className="form-label">
                Số điện thoại di động
              </label>
              <input
                type="text"
                id="numberPhone"
                name="numberPhone"
                pattern="[0]{1}[0-9]{9}"
                className="form-control"
                required
                value={numberPhone}
                onChange={handleInputChange}
                placeholder="035977xxxx"
              />
              <div className="invalid-feedback">
                Số điện thoại di động nhập đủ 10 số
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="date" className="form-label">
                Ngày sinh
              </label>
              <input
                type="text"
                className="form-control single-date"
                id="date"
                name="date"
                required
                value={date}
                onChange={handleInputChange}
                placeholder="dd/mm/yyyy"
              />
              <div className="invalid-feedback">
                Ngày sinh theo định dạng ngày/tháng/năm, người ứng tuyển trên 18
                tuổi
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="gender" className="form-label">
                Giới tính
              </label>
              <select
                id="gender"
                name="gender"
                className="form-select"
                required
                value={gender}
                onChange={handleInputChange}
              >
                <option value="">Chọn giới tính</option>
                <option value="Nam">Nam</option>
                <option value="Nữ">Nữ</option>
              </select>
              <div className="invalid-feedback">Vui lòng chọn giới tính</div>
            </div>
            <div className="mb-4">
              <label htmlFor="cccd" className="form-label">
                Căn cước công dân (12 số) hoặc Chứng minh nhân dân (9 số)
              </label>
              <input
                type="text"
                className="form-control"
                id="cccd"
                name="cccd"
                pattern="[0-9]{9}|[0-9]{12}"
                required
                value={cccd}
                onChange={handleInputChange}
                placeholder="08209xxxxxxx"
              />
              <div className="invalid-feedback">
                Vui lòng nhập Căn cước công dân (12 số) hoặc Chứng minh nhân dân
                (9 số)
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="imageUrl1" className="form-label">
                Hình mặt trước CCCD
              </label>
              <input
                className="form-control"
                type="file"
                id="imageUrl1"
                accept="image/*"
                name="imageUrl1"
                required
                onChange={handleFileChange}
              />
              <div className="invalid-feedback">
                Vui lòng chọn hình mặt trước CCCD
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="imageUrl2" className="form-label">
                Hình mặt sau CCCD
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                id="imageUrl2"
                name="imageUrl2"
                required
                onChange={handleFileChange}
              />
              <div className="invalid-feedback">
                Vui lòng chọn hình mặt sau CCCD
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="form-label">
                Email (Nếu Có)
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                name="email"
                placeholder="Email của Ứng Viên (nếu có)"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="tdvh" className="form-label">
                Trình độ văn hóa VD: 12/12
              </label>
              <select
                id="tdvh"
                name="tdvh"
                required
                className="form-control"
                value={tdvh}
                onChange={handleInputChange}
              >
                <option value="">Chọn trình độ văn hóa</option>
                {[...Array(12).keys()].map((i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}/12
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">
                Vui lòng chọn trình độ văn hoá
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="chuyenmon" className="form-label">
                Trình độ chuyên môn (nếu có)
              </label>
              <select
                id="chuyenmon"
                name="chuyenmon"
                className="form-control"
                value={chuyenmon}
                onChange={handleInputChange}
              >
                <option value="">Chuyên Môn của Ứng Viên (nếu có)</option>
                <option value="Trung Cấp">Trung Cấp</option>
                <option value="Cao Đẳng">Cao Đẳng</option>
                <option value="Đại Học">Đại Học</option>
                <option value="Không có">Không có</option>
              </select>
            </div>
            {chuyenmon !== "" &&
              chuyenmon !== "Chuyên Môn của Ứng Viên (nếu có)" && (
                <div className="mb-4">
                  <label htmlFor="chuyennganh" className="form-label">
                    Chuyên ngành ứng viên
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    id="chuyennganh"
                    name="chuyennganh"
                    value={chuyennganh}
                    onChange={handleInputChange}
                    placeholder="Ngành Quản trị kinh doanh"
                  />
                </div>
              )}
            <div className="mb-4">
              <label htmlFor="taynghe" className="form-label">
                Tay nghề
              </label>
              <select
                id="taynghe"
                name="taynghe"
                required
                className="form-control"
                value={taynghe}
                onChange={handleInputChange}
              >
                <option value="">Tay nghề của Ứng viên</option>
                <option value="Biết may">Biết May</option>
                <option value="Không biết may">Không biết may</option>
              </select>
              <div className="invalid-feedback">Vui lòng chọn tay nghề may</div>
            </div>

            <div class="mb-4">
              <label for="vtut" class="form-label">
                Vị trí ứng tuyển
              </label>
              <select
                id="vtut"
                name="vtut"
                required
                class="form-select"
                value={vtut}
                onChange={handleInputChange}
              >
                <option value="">Chọn vị trí ứng tuyển</option>
                <option value="Công nhân may">Công nhân may</option>
                <option value="Công nhân cắt">Công nhân cắt</option>
                <option value="Công nhân ủi">Công nhân ủi</option>
                <option value="Đóng gói">Đóng gói</option>
                <option value="Kiểm hóa">Kiểm hóa</option>
                <option value="Nhân viên kho">Nhân viên kho</option>
                <option value="Nhân viên cơ điện">Nhân viên cơ điện</option>
                <option value="Bảo vệ">Bảo vệ</option>
                <option value="Nhân viên Văn phòng">Nhân viên Văn phòng</option>
              </select>
              <div class="invalid-feedback">Vui lòng chọn vị trí ứng tuyển</div>
            </div>
            <div className="mb-4">
              <label htmlFor="tttd" className="form-label">
                Kênh thông tin tuyển dụng
              </label>
              <select
                id="tttd"
                name="tttd"
                required
                className="form-select"
                value={tttd}
                onChange={handleInputChange}
              >
                <option value="">Chọn kênh thông tin</option>
                <option value="Banner cổng bảo vệ">Banner cổng bảo vệ</option>
                <option value="Banner facebook">Banner facebook</option>
                <option value="Ứng viên tự đến công ty">
                  Ứng viên tự đến công ty
                </option>
                <option value="Người thân giới thiệu">
                  Người thân giới thiệu
                </option>
                <option value="Băng rôn">Băng rôn</option>
                <option value="Kênh khác">Kênh khác</option>
              </select>
              <div className="invalid-feedback">
                Vui lòng chọn kênh thông tin
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="tttd" className="form-label">
                Để xác minh thông tin người dùng bạn vui lòng chọn vào đường
                link{" "}
                <a href="https://congtien.com.vn" target="_blank">
                  https://congtien.com.vn.
                </a>{" "}
                Đi tới cuối trang web (theo hình hướng dẫn ở bên dưới) và nhập 3
                chữ số cuối cùng hotline của công ty
              </label>
              <div class="row">
                <div class="col-12 col-md-6">
                  <img
                    className="img-fluid img-thumbnail border border-warning"
                    src={img}
                  />
                </div>
                <div class="col-12 col-md-6">
                  <input
                    type="text"
                    className="form-control mt-2 mb-3"
                    onChange={(e) => setIsCheck(e.target.value)} // Cập nhật giá trị state khi người dùng nhập liệu
                    required
                    placeholder="Nhập 3 chữ số cuối cùng hotline
                    của công ty"
                  />
                </div>
              </div>

              <div className="invalid-feedback">
                Vui lòng chọn kênh thông tin
              </div>
            </div>

            <div class="row pb-4">
              {vtut === "Nhân viên Văn phòng" ? (
                <div class="col-md-9">
                  <label for="xacnhan" class="lh-base ">
                    <b className="b-red">Lưu ý:</b>
                    <br />
                    Nếu Anh/chị ứng tuyển nhân viên văn phòng, vui lòng
                    <b className="b-red"> gửi CV </b>
                    qua email
                    <a href="mailto:nhansu@congtien.com.vn">
                      <b className="b-red"> nhansu@congtien.com.vn</b>
                    </a>
                  </label>
                </div>
              ) : (
                <div class="col-md-9">
                  <label for="xacnhan" class=" lh-base ">
                    <b className="b-red">Lưu ý:</b> Sau khi đăng ký ứng tuyển
                    thành công trên Website mời Anh/Chị đến Cổng bảo vệ Công ty
                    CP May Công Tiến vào lúc
                    <b className="b-red"> 7 giờ 30 phút </b>sáng ngày
                    <b className="b-red"> {targetDate} </b>để được hướng dẫn
                    phỏng vấn và nhận việc.
                    <b className="b-red">
                      Vui lòng mặc áo sơ mi trắng, mang theo CCCD bản gốc và hồ
                      sơ ứng tuyển (nếu có);{" "}
                    </b>
                    chuẩn bị bút xanh, cơm trưa và nước uống để nhận việc trong
                    trường hợp ứng viên vượt qua vòng phỏng vấn ./. Chọn vào xác
                    nhận thông tin ứng tuyển này. <br />
                  </label>
                </div>
              )}
              <div class="col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="isSure"
                    name="isSure"
                    onChange={handleCheck}
                    checked={xacnhan}
                    required
                  />
                  <div class="invalid-feedback">
                    Vui lòng xác nhận thông tin khai báo là chính xác.
                  </div>
                  <label
                    class="text-success-sub form-check-label text-success"
                    for="isSure"
                  >
                    Xác nhận đã đọc lưu ý
                  </label>
                </div>
              </div>
            </div>
            <div className="text-center pb-5">
              {!showXacnhan && xacnhan && (
                <button
                  onClick={handleXacnhan}
                  className="btn btn-primary btn-lg"
                >
                  XÁC NHẬN THÔNG TIN
                </button>
              )}
              {showXacnhan && (
                <button type="submit" className="btn btn-primary btn-lg">
                  NỘP ĐƠN VÀ NHẬN THÔNG BÁO
                </button>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default RegistrationForm;
